import { apiDomain } from '../consts'

const fetchPutRepeatPassword = async (email, password, confirmPassword) => {
  const opts = { email, password, confirmPassword };
  return await fetch(`${apiDomain}/reset-password`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "post",
    body: JSON.stringify(opts)
  }).then(response => response.json());
};

const fetchPutForgotPasswordCode = async (email, code) => {
  const opts = { email, token: code };
  return await fetch(`${apiDomain}/validate-reset-token`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "post",
    body: JSON.stringify(opts)
  }).then(response => response.json());
};


const fetchForgotPassword = async (email) => {
  const opts = { email };
  return await fetch(`${apiDomain}/forgot-password`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "post",
    body: JSON.stringify(opts)
  }).then(response => response.json());
};


const fetchRequestSignIn = async (username, password) => {
  const opts = { email: username, password };
    return await fetch(`${apiDomain}/login`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    }).then(response => response.json());
}

const fetchRegister = async (data) => {
  return await fetch(`${apiDomain}/register`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "post",
    body: JSON.stringify(data)
  }).then(response => response.json());
};

export {
  fetchRequestSignIn,
  fetchRegister,
  fetchForgotPassword,
  fetchPutForgotPasswordCode,
  fetchPutRepeatPassword
};