import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { Prices } from "../";
import { fetchSizes } from "../../services/sizes";

class Sizes extends Component {
  constructor(props) {
    super();
  }

  async componentDidMount() {
    const { setSizesCollection, getProductSelected, setNextStep } = this.props;
    const idProduct = getProductSelected();
    const responseSizes = await fetchSizes(idProduct);
    if (responseSizes.message) {
      return await setNextStep(true);
    }
    else {
      setSizesCollection(responseSizes);
    }
    
  }

  getSizes = () => {
    const { props } = this;
    const { items, setSize, getSizeSelected } = props;
    return items.map((item, key) => {
      const sizeIsSelected = getSizeSelected() === item.id;
      return (
        <Col
          className="SizesElementColumn"
          key={key}
          onClick={() => setSize(item.id)}
        >
          <div className="SizeWrapper BoxShadow">
            {sizeIsSelected ? <div className="SelectedProduct">✓</div> : ""}
            <div
              className="SizeElement"
              style={{
                width: item.pixelSize + "px",
                height: item.pixelSize + "px"
              }}
            ></div>
            <br />
            <div className="SizeFooter">
              <span className="BreakingBreadFont">{item.name}</span>
              <Prices price={item.price} />
            </div>
          </div>
        </Col>
      );
    });
  };

  render() {
    const sizes = this.getSizes();
    return sizes;
  }
}

export default Sizes;
