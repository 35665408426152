import React, { Component } from 'react';

class Titlebar extends Component {
    constructor (props) {
        super();
    }

    render() {
      const { props } = this;
      const { title, step, color } = props;
        return (
          <div className="Titlebar">
            <div className="Titlebar_number">
              <span className="Titlebar_step" style={{ color }}>
                Paso
              </span>
              <br />
              <span style={{ color, fontSize: 152 }}>{step}</span>
            </div>
            <span className="Titlebar_seleccione">Seleccione</span> <br />
            {title}
          </div>
        );
    }
}

export default Titlebar;