import { apiDomain } from '../consts'

const fetchCountries = async () => {
    return await fetch(
      `${apiDomain}/headsquarters/countries/`
    ).then(response => response.json());
}

const fetchCities = async country => {
  return await fetch(
    `${apiDomain}/headsquarters/country/${country}/cities`
  ).then(response => response.json());
}

const fetchHeadquarters = async (country, city) => {
  return await fetch(
    `${apiDomain}/headsquarters/country/${country}/city/${city}`
  ).then(response => response.json());
}

export {
  fetchCountries,
  fetchCities,
  fetchHeadquarters
}