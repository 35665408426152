import { apiDomain } from '../consts'

const fetchCategories = async params => {
  const { productSeleted, sizeId, isRecommendation } = params;
    return await fetch(
      `${apiDomain}/categories/product/${productSeleted}/${isRecommendation ? 'recommendation' : `size/${sizeId}`}`
    ).then(response => response.json());
}

const fetchCategory = async params => {
  const { productSeleted, sizeSelected, step, isRecommendation } = params;
  return await fetch(
    `${apiDomain}/categories/product/${productSeleted}/${isRecommendation ? 'recommendation' : `size/${sizeSelected}`}/step/${step}`
  ).then(response => response.json())
}

export {
  fetchCategories,
  fetchCategory
}