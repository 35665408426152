import React, { Component } from "react";

class Prices extends Component {
  render() {
    let { price, className } = this.props;
    if (!price || price <= 0) return false;
    const withDivitionPoint = +price > 999;
    const totalPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const priceValue = withDivitionPoint ? totalPrice.split(".") : price;
    return (
      <div style={{ position: "relative" }} className={className}>
        <span className="BigValue">
          ${withDivitionPoint ? priceValue[0] + "." : price}
        </span>
        {withDivitionPoint && (
          <span className="SmallValue">{priceValue[1]}</span>
        )}
      </div>
    );
  }
}

export default Prices;
