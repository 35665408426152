import { apiDomain } from '../consts'

const fetchProducts = async (headquarter_id) => {
    return await fetch(
      `${apiDomain}/headsquarters/${headquarter_id}`
    ).then(response => response.json());
}

export {
    fetchProducts
}