const normalizeIsBaseAttribute = (categories, deleteIngredients = false) => {
    if (!categories || categories.length < 1) return categories

    categories.forEach(category => {
        if (deleteIngredients) delete category.ingredients
        category.itemsSelected.forEach(item => {
          if (item.isBase === 0 || !item.isBase) {
            item.isBase = 0;
          } else {
            item.isBase = 1;
          }
        });
      });
    return categories
}

export default normalizeIsBaseAttribute