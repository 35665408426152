import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { fetchRegister } from "../services/user";
import { toast } from "react-toastify";
import { Redirect, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 5000,
  draggable: false
});

class Register extends Component {
  state = {
    isRegistered: false,
    email: null,
    firstName: null,
    lastName: null,
    birthday: null,
    phone: null,
    password: null,
    isLoading: false
  };

  handleEmail = e => {
    const email = e.target.value;
    this.setState({
      email
    });
  };

  handleLastname = e => {
    const lastName = e.target.value;
    this.setState({
      lastName
    });
  };

  handleFirstname = e => {
    const firstName = e.target.value;
    this.setState({
      firstName
    });
  };

  handlePhone = e => {
    const phone = e.target.value;
    this.setState({
      phone
    });
  };

  handleBirthday = e => {
    const birthday = e.target.value;
    this.setState({
      birthday
    });
  };

  handlePassword = e => {
    const password = e.target.value;
    this.setState({
      password
    });
  };

  sendErrors = (errors = []) => {
    if (errors.length) {
      errors.forEach(error => {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      isLoading: true
    });
    const {
      email,
      firstName,
      lastName,
      phone,
      birthday,
      password
    } = this.state;
    const response = await fetchRegister({
      email,
      firstName,
      lastName,
      phone,
      birthday,
      password
    });
    if (response.token) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("data", JSON.stringify(response.user));
      toast.success("Bienvenido a Gokela", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({
        isRegistered: true
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.sendErrors(response.errors);
    }
    this.setState({
      isLoading: false
    });
  };
  render() {
    const { isLoading, isRegistered } = this.state;
    const token = localStorage.getItem("token");
    if (token && isRegistered) {
      toast.success("Usuario creado con exito, inicie sesion.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return <Redirect to="/" />;
    }

    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <div className="AppMain">
          <div className="CategoryMainContainer">
            <div className="LogoGreen">
              <img src="./logo-green.png" alt="This is the logo of the gokela" />
            </div>
            <p className="TextWelcome">Registrate en Gokela</p>
            <div className="GreenInput BoxShadow">
              <input
                autoFocus
                type="text"
                placeholder="Correo electronico"
                disabled={isLoading}
                onChange={e => this.handleEmail(e)}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="password"
                placeholder="Contraseña"
                disabled={isLoading}
                onChange={e => this.handlePassword(e)}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="text"
                placeholder="Nombres"
                disabled={isLoading}
                onChange={e => this.handleFirstname(e)}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="text"
                placeholder="Apellidos"
                disabled={isLoading}
                onChange={e => this.handleLastname(e)}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="text"
                placeholder="Celular"
                disabled={isLoading}
                onChange={e => this.handlePhone(e)}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="date"
                placeholder="Fecha de nacimiento"
                disabled={isLoading}
                onChange={e => this.handleBirthday(e)}
              />
            </div>
            <div className="OrangeInput">
              <button type="submit" className="BoxShadow" disabled={isLoading}>
                Registrarme
              </button>
            </div>
            <hr />
            <div className="afterRegister TextWelcome">
              <Link className="TextWelcome BreakingBreadFont" to="/entrar">
                Iniciar sesión
              </Link>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default Register;
