import React, { Component, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Prices } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faArrowCircleRight,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { apiDomain } from "../../consts";

const DetailsItemPopup = props => {
  const { message, setVisibleDescription, item } = props;
  return item.hasDescription ? (
    <div
      className="DetailsItemContainer"
      onMouseOver={() => setVisibleDescription(true)}
    >
      <div className="arrow-up"></div>
      <div className="DetailsItemPopup">{message}</div>
    </div>
  ) : (
    <div />
  );
};

const DetailsItem = props => {
  const [visibleDescription, setVisibleDescription] = useState(false);

  const { item, category, thisItemIsActive, itemIsBase } = props;
  const isChargeBase = itemIsBase && category.isChargeBase;
  const isChargeAdditional = !itemIsBase && category.isChargeAdditional;
  const showPrice = (isChargeBase || isChargeAdditional) && thisItemIsActive;

  const validationClassName =
    item.price > "0" && showPrice ? "" : "withoutPrice";
  const srcImage = apiDomain + item.image;

  return (
    <div
      className="DetailsItem"
      onMouseOver={() => setVisibleDescription(true)}
      onMouseOut={() => setVisibleDescription(false)}
    >
      <div className={validationClassName}>
        <img src={srcImage} height={80} width={100} alt="Ingredient Food" />
        <p className="BreakingBreadFont">{item.name}</p>
        {showPrice ? <GetPrice item={item} /> : <div />}
      </div>
      {visibleDescription ? (
        <DetailsItemPopup
          item={item}
          message={item.description}
          setVisibleDescription={setVisibleDescription}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

const InfoMessage = props => {
  const { description, hasDescription } = props;
  return hasDescription ? (
    <div className="DescriptionCategoryMessageContainer">
      <div className="DescriptionCategoryMessage BoxShadowWithoutHover">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <p>{description}</p>
      </div>
    </div>
  ) : (
    <div />
  );
};

const GetPrice = props => {
  const { item } = props;
  return item.price > "0" ? (
    <Row>
      <Col>
        <Prices price={item.price} />
      </Col>
    </Row>
  ) : (
    <div />
  );
};

const LabelAmount = props => {
  const { amountAvailableToShow, thisItemIsActive } = props;
  return amountAvailableToShow && thisItemIsActive.amount >= 1 ? (
    <div className="AmountItem">{thisItemIsActive.amount}</div>
  ) : (
    <div />
  );
};

const LabelItemStatus = props => {
  const { itemIsBase, thisItemIsActive } = props;
  const status = thisItemIsActive ? (itemIsBase ? "✓" : "Adicional") : "";
  const styleStatus = {
    backgroundColor: itemIsBase ? "#99b83c" : "#ec7124"
  };
  return (
    <div className="itemIsActive" style={styleStatus}>
      {status}
    </div>
  );
};

const Buttons = props => {
  const { thisItemIsActive, addAmountToItem, item, step } = props;
  return (
    <div className="ButtonAdds">
      {thisItemIsActive.amount > 1 ? (
        <div
          className="buttonIngredient leftButton BoxShadow"
          onClick={() => addAmountToItem(step, item.id, false)}
        >
          <FontAwesomeIcon icon={faMinusCircle} />
        </div>
      ) : (
        <div />
      )}
      <div
        className="buttonIngredient rightButton BoxShadow"
        onClick={() => addAmountToItem(step, item.id, true)}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </div>
    </div>
  );
};

const IngredientsCollections = props => {
  const {
    ingredients,
    itemIsActive,
    step,
    isBaseUpgradeable,
    isAdditionalUpgradeable,
    setItemActive,
    category,
    addAmountToItem
  } = props;

  return ingredients.map((item, key) => {
    const thisItemIsActive = itemIsActive(step, item.id);
    const itemIsBase = thisItemIsActive && thisItemIsActive.isBase;
    const isBaseAndUpgradeable = itemIsBase && isBaseUpgradeable;
    const isAdditionalAndUpradeable = !itemIsBase && isAdditionalUpgradeable;
    const isUpgradeable = isBaseAndUpgradeable || isAdditionalAndUpradeable;
    const amountAvailableToShow = thisItemIsActive && isUpgradeable;
    return (
      <Col key={key} sm="2" className="IngredientsContainer BoxShadow">
        <div
          className="SelectorItem"
          onClick={() => setItemActive(step, item.id)}
        >
          <LabelAmount
            amountAvailableToShow={amountAvailableToShow}
            thisItemIsActive={thisItemIsActive}
          />

          {thisItemIsActive ? (
            <LabelItemStatus
              itemIsBase={itemIsBase}
              thisItemIsActive={thisItemIsActive}
            />
          ) : (
            <div />
          )}

          <DetailsItem
            itemIsBase={itemIsBase}
            item={item}
            category={category}
            thisItemIsActive={thisItemIsActive}
          />
        </div>
        {isUpgradeable && thisItemIsActive ? (
          <Buttons
            thisItemIsActive={thisItemIsActive}
            addAmountToItem={addAmountToItem}
            item={item}
            step={step}
          />
        ) : (
          ""
        )}
      </Col>
    );
  });
};

const SkipButton = props => {
  const { hasSkipSelectionButton, setSkipButton } = props;
  if (!hasSkipSelectionButton) return <div />;
  return (
    <Col
      sm="2"
      className="IngredientsContainer BoxShadow SkipButton"
      onClick={() => setSkipButton()}
    >
      <FontAwesomeIcon icon={faArrowCircleRight} className="SkipButtonIcon" />
      No quiero nada aquí
    </Col>
  );
};

const ExtensibleButton = props => {
  const {
    showExtensiblePromotion,
    extensibleTitle,
    category,
    priceExtensible,
    setExtensible,
    isExtensible,
    extensibleDescription,
    extensibleItems,
    selectExtensibleItem,
    step
  } = props;

  if (!showExtensiblePromotion) return <div />;
  return (
    <div className="containerExtensibleButton">
      <div className="RippleAnimationElement">
        <div className="CircleRipple"></div>
      </div>
      <div className="extensibleButton BoxShadow">
        <span
          className="titleExtensible"
          onClick={() => setExtensible(category.step)}
          role="img"
          aria-label={extensibleTitle}
        >
          👉 {extensibleTitle} {priceExtensible > 0 ? `por $${priceExtensible}` : '' } 👈
        </span>

        {isExtensible ? (
          <div>
            <p className="titleExtensibleDescription">
              {extensibleDescription}
            </p>
            <Row className="extensibleContainer">
              {extensibleItems.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={
                      item.selected
                        ? "extensibleItemSelected BoxShadow"
                        : "extensibleItem"
                    }
                    onClick={() => selectExtensibleItem(step, item.id)}
                  >
                    {key + 1}. {item.name}
                  </div>
                );
              })}
            </Row>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const CategoryCollections = props => {
  const {
    globalStep,
    categories,
    itemIsActive,
    setItemActive,
    addAmountToItem,
    setExtensible,
    selectExtensibleItem,
    setSkipButton
  } = props;

  return categories.map((category, key) => {
    const {
      ingredients,
      isBaseUpgradeable,
      isAdditionalUpgradeable,
      step,
      hasSkipSelectionButton,
      priceExtensible,
      extensibleTitle,
      extensibleDescription,
      extensibleItems,
      showExtensiblePromotion,
      isExtensible
    } = category;

    if (globalStep !== step) return <div />;
    return (
      <div>
        <div key={key} className="CategoryMainContainer">
          <InfoMessage
            description={category.description}
            hasDescription={category.hasDescription}
          />
          <Row className="CategoryIngredientsContainer">
            <SkipButton
              hasSkipSelectionButton={hasSkipSelectionButton}
              setSkipButton={setSkipButton}
            />
            <IngredientsCollections
              step={step}
              itemIsActive={itemIsActive}
              setItemActive={setItemActive}
              addAmountToItem={addAmountToItem}
              ingredients={ingredients}
              isBaseUpgradeable={isBaseUpgradeable}
              isAdditionalUpgradeable={isAdditionalUpgradeable}
              category={category}
            />
          </Row>
          <ExtensibleButton
            showExtensiblePromotion={showExtensiblePromotion}
            extensibleTitle={extensibleTitle}
            category={category}
            priceExtensible={priceExtensible}
            setExtensible={setExtensible}
            isExtensible={isExtensible}
            extensibleDescription={extensibleDescription}
            extensibleItems={extensibleItems}
            selectExtensibleItem={selectExtensibleItem}
            step={step}
          />
        </div>
      </div>
    );
  });
};

class Category extends Component {
  constructor(props) {
    super();
  }

  render() {
    const {
      categories,
      itemIsActive,
      setItemActive,
      globalStep,
      addAmountToItem,
      setExtensible,
      selectExtensibleItem,
      setSkipButton
    } = this.props;

    return (
      <div>
        <CategoryCollections
          categories={categories}
          itemIsActive={itemIsActive}
          setItemActive={setItemActive}
          globalStep={globalStep}
          addAmountToItem={addAmountToItem}
          setExtensible={setExtensible}
          selectExtensibleItem={selectExtensibleItem}
          setSkipButton={setSkipButton}
        />
      </div>
    );
  }
}

export default Category;
