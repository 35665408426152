import React, { Component } from "react";
import { Products, Sizes, Category, Navigation, Resume } from "../components";
import { Row } from "react-bootstrap";
import { fetchProducts } from "../services/products";
import { fetchCategory } from "../services/category";
import { fetchCreateOrder } from "../services/orders";
import { Redirect } from "react-router-dom";
import "../styles/App.css";
import { toast } from "react-toastify";
import { apiDomain } from "../consts";
import { normalizeIsBaseAttribute } from "../utils";
import {
  fetchCountries,
  fetchCities,
  fetchHeadquarters
} from "../services/headquarters";

const ProductsContainer = props => {
  const { products, getProductSelected, setProduct } = props;
  return (
    <div className="AppMargin" id="products_container">
      <Row>
        <Products
          items={products}
          getProductSelected={getProductSelected}
          setProduct={setProduct}
        />
      </Row>
    </div>
  );
};

const ChoiseOptionModal = props => {
  const { setNextStep, product, setRecommendation } = props;
  const { image, name, hasRecommendations, hasCategories } = product;
  return (
    <div className="ChoiseOptionModalContainer">
      <div className="ChoiseOptionModal BoxShadowWithoutHover">
        <div className="ChoiseOptionModalBody">
          <img
            src={`${apiDomain}${image}`}
            height="150"
            width="150"
            alt="Product"
          />
          <h1>
            {name} <br /> <small>¿Qué deseas hacer?</small>
          </h1>
        </div>

        <div className="ChoiseOptionModalButtons">
          <div className="GreenInput">
            {hasCategories ? (
              <button type="submit" className="BoxShadow" onClick={setNextStep}>
                Crear mi propio Menu
              </button>
            ) : (
              <div />
            )}
          </div>
          <div className="GreenInput">
            {hasRecommendations ? (
              <button
                type="submit"
                className="BoxShadow"
                onClick={setRecommendation}
              >
                Ver Recomendados
              </button>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

class Wizard extends Component {
  state = {
    step: 1,
    showOptionsBeforeToContinueToSizes: false,
    finished: false,
    productSeleted: null,
    sizeSelected: null,
    sizes: [],
    products: [],
    categories: [],
    total: 0,
    actualSubtotal: 0,
    isDelivery: null,
    isRecommendation: false,
    deliveryPersonalInformation: {
      fullName: null,
      address: null,
      phone: null,
      observations: null
    },
    shoppingSet: [],
    countryId: null,
    cityId: null,
    headquarterId: null,
    countries: [],
    cities: [],
    headquarters: []
  };

  removeOrderInShoppingSet = async orderIndex => {
    const { shopping_set } = this.state;
    const newShoppingSet = shopping_set.filter((order, key) => {
      return orderIndex !== key;
    });
    await this.setState({
      shopping_set: newShoppingSet
    });
    this.getTotalPriceInCategory();
  };

  toggleOptionsProducts = () => {
    const { showOptionsBeforeToContinueToSizes } = this.state;
    this.setState({
      showOptionsBeforeToContinueToSizes: !showOptionsBeforeToContinueToSizes
    });
  };

  getAllCategoriesToRequest = () => {
    const { categories } = this.state;
    return categories.map(category => {
      return {
        categoryId: category.id,
        hasExtensible: category.isExtensible,
        priceExtensible: category.priceExtensible,
        itemsSelected: category.itemsSelected,
        extensiblesSelected: this.getExtensibleItemsSelected(category.step)
      };
    });
  };

  sendOrder = async () => {
    const {
      deliveryPersonalInformation,
      isDelivery,
      // productSeleted,
      // sizeSelected,
      total,
      shoppingSet
    } = this.state;
    // const categories = this.getAllCategoriesToRequest();
    const user = JSON.parse(localStorage.getItem("data"));

    // Joining actualProduct with others into shoppingSet
    // shoppingSet.push(({
    //   categories,
    //   productSeleted,
    //   sizeSelected
    // }))
    this.insertInOrderShoppingSet();

    // Normalize isBase attribute on itemsSelected value (someone has 0/1 and other false/true)
    shoppingSet.forEach(product => {
      product.categories = normalizeIsBaseAttribute(product.categories);
    });

    const dataRequest = {
      // productId: productSeleted,
      // sizeId: sizeSelected,
      userId: user.id,
      ...deliveryPersonalInformation,
      isDelivery,
      // categories,
      products: shoppingSet,
      total
    };

    const orderCreated = await fetchCreateOrder(dataRequest);
    if (orderCreated.message === "OK") {
      toast.success("Orden creada con éxito");
    } else {
      toast.error(orderCreated.message);
    }
  };

  setDeliveryPersonalInformation = async form => {
    const { fullName, address, phone, observations } = form;
    await this.setState({
      deliveryPersonalInformation: { fullName, address, phone, observations }
    });
    this.sendOrder();
  };

  setDelivery = async (isDelivery = true) => {
    await this.setState({
      isDelivery
    });
    this.getTotalPriceInCategory();
  };

  setFinishedPicker = (isFinished = false) => {
    this.setState({
      finished: isFinished
    });
  };

  getProduct = productSeleted => {
    const { products } = this.state;
    return products.find(item => item.id === productSeleted);
  };

  getSize = sizeSelected => {
    const { sizes } = this.state;
    return sizes.find(item => item.id === sizeSelected);
  };

  getSizeSelected = () => {
    const { sizeSelected } = this.state;
    return sizeSelected;
  };

  setSizeCollection = sizes => {
    this.setState({
      sizes
    });
  };

  setSize = async sizeId => {
    await this.setState({
      sizeSelected: sizeId
    });
    this.getTotalPriceInCategory();
    this.setNextStep();
  };

  getProductSelected = () => {
    const { productSeleted } = this.state;
    return productSeleted;
  };

  setSkipButton = async () => {
    const { step, categories } = this.state;
    const categoryIndex = this.getCategoryIndex(step);
    categories[categoryIndex].itemsSelected = [];
    categories[categoryIndex].hasExtensible = false;

    await this.setState({
      categories
    });

    await this.setNextStep(true);
  };

  setNextStep = async (skip = false) => {
    let {
      step,
      isRecommendation,
      showOptionsBeforeToContinueToSizes
    } = this.state;
    const category = this.getCategory(step);
    const validation = this.validateNextButton();

    if (step === 1 && !showOptionsBeforeToContinueToSizes) {
      return this.toggleOptionsProducts();
    }

    if (!validation && skip === false) return false;
    if (category && category.isLatest) {
      step = "*";
    } else if (isRecommendation && step === 1) {
      step = 3;
    } else {
      step = step + 1;
    }

    this.setState(
      {
        step
      },
      () => {
        this.getTotalPriceInCategory();
        if (step >= 3) this.requestStepCategory();
      }
    );
  };

  getSummatoryTotalOfSteps = category => {
    let totalCategory = 0;
    const itemsSelected = category.itemsSelected;
    itemsSelected.forEach(e => {
      const getSelectedIngredient = category.ingredients.find(
        ingredient => ingredient.id === e.id
      );
      const chargeBases = category.hasPortionBase && category.isChargeBase;
      const chargeAdditionals =
        category.hasPortionAdditional && category.isChargeAdditional;
      const chargeIfIsUnlimitedAdditionals =
        category.hasUnlimitedAdditional && category.isChargeAdditional;
      const chargeOnlyAdditionals =
        category.isChargeAdditional && !category.isChargeBase;
      const basesHasBeenComplete =
        itemsSelected.length > category.portionBaseAmount;

      if (
        (chargeBases && e.isBase) ||
        (chargeAdditionals && !e.isBase) ||
        (chargeIfIsUnlimitedAdditionals && !e.isBase) ||
        (chargeOnlyAdditionals && basesHasBeenComplete && !e.isBase)
      ) {
        totalCategory += getSelectedIngredient.price * e.amount;
      }
    });
    return totalCategory;
  };

  insertInOrderShoppingSet = () => {
    const {
      productSeleted: productId,
      sizeSelected: sizeId,
      categories,
      shoppingSet,
      actualSubtotal
    } = this.state;
    const latestOrder = {
      productId,
      sizeId,
      categories: categories.map(category => {
        return {
          categoryId: category.id,
          hasExtensible: category.hasExtensible,
          priceExtensible: category.priceExtensible,
          itemsSelected: category.itemsSelected,
          ingredients: category.ingredients,
          extensiblesSelected: category.extensibleItems.filter(
            extensible => extensible.selected
          )
        };
      }),
      subtotal: actualSubtotal
    };
    shoppingSet.push(latestOrder);
    this.setState({
      step: 1,
      categories: [],
      productSeleted: null,
      sizeSelected: null,
      shoppingSet,
      showOptionsBeforeToContinueToSizes: false,
      finished: false,
      isRecommendation: 0,
      actualSubtotal: 0
    });
  };

  summarySizeSelectedAndCategories = (order, isTotal = true) => {
    let total = 0;
    const { sizeSelected, categories } = order;
    const getSize = this.getSize(sizeSelected);
    if (getSize) total += getSize.price;
    categories.forEach(category => {
      if (category.isExtensible && category.hasExtensible) {
        total += category.priceExtensible;
      }
      total += this.getSummatoryTotalOfSteps(category);
    });
    if (isTotal) {
      total = this.getBenefitsInWayDelivery(total);
    }
    return total;
  };

  getTotalSumCategoriesAndSizes = (bulkOrders, isTotal) => {
    let total = 0;
    bulkOrders.forEach(order => {
      total += order.subtotal
        ? order.subtotal
        : this.summarySizeSelectedAndCategories(order, isTotal);
    });
    return total;
  };

  iterationToGetTotalPrice = () => {
    const { categories, sizeSelected, shoppingSet } = this.state;
    const bulkOrders = [
      {
        categories,
        sizeSelected
      },
      ...shoppingSet
    ];
    return this.getTotalSumCategoriesAndSizes(bulkOrders, true);
  };

  iterationToGetSubtotalPrice = () => {
    const { categories, sizeSelected } = this.state;
    return this.getTotalSumCategoriesAndSizes(
      [{ categories, sizeSelected }],
      false
    );
  };

  getBenefitsInWayDelivery = (total = 0) => {
    const { isDelivery, productSeleted } = this.state;
    if (total === 0 || isDelivery === null) return total;
    const product = this.getProduct(productSeleted);
    if (isDelivery) {
      total += +product.deliveryCharge;
    } else {
      const discountPercent = +product.pickupDiscountPercentage;
      const discountTotal = total - (total / 100) * discountPercent;
      total = discountTotal;
    }
    return total;
  };

  getTotalPriceInCategory = () => {
    const total = this.iterationToGetTotalPrice();
    const subtotal = this.iterationToGetSubtotalPrice();
    this.setState({
      total,
      actualSubtotal: subtotal
    });
  };

  removeSelectedCategory = async step => {
    const { categories } = this.state;
    const categoryIndex = this.getCategoryIndex(step);
    categories.splice(categoryIndex, 1);
    await this.setState({
      categories
    });
  };

  setBackStep = async () => {
    let {
      step,
      categories,
      isRecommendation,
      productSeleted,
      sizeSelected
    } = this.state;
    const isRecommendationAndStep = step === 3 && isRecommendation;
    const withoutSize = sizeSelected === null && step === 3;
    if (step === "*") {
      const lastCategorySelected = categories[categories.length - 1];
      const lastCategorySelectedStep = lastCategorySelected.step;
      step = lastCategorySelectedStep;
    } else if (step === 2) {
      step = step - 1;
      this.setState({
        total: 0,
        sizeSelected: null
      });
      productSeleted = null;
    } else if (step >= 3) {
      await this.removeSelectedCategory(step);
      step = isRecommendationAndStep || withoutSize ? 1 : step - 1;

      if (isRecommendationAndStep) {
        isRecommendation = 0;
        categories = [];
        productSeleted = null;
      }
      this.getTotalPriceInCategory();
    } else if (step === 1) {
      this.toggleOptionsProducts();
    }

    this.setState({
      step,
      isRecommendation,
      showOptionsBeforeToContinueToSizes: false,
      productSeleted,
      categories
    });
  };

  setProduct = productSeleted => {
    this.setState({
      productSeleted
    });
    this.toggleOptionsProducts();
  };

  setExtensible = step => {
    const { categories } = this.state;
    let { total } = this.state;
    let category = this.getCategory(step);
    const categoryIndex = this.getCategoryIndex(step);
    category.isExtensible = !category.isExtensible;
    categories[categoryIndex] = category;
    this.setState({ categories, total });
    this.getTotalPriceInCategory();
  };

  getCategory = step => {
    const { categories } = this.state;
    return categories.find(item => item.step === step);
  };

  getCategoryIndex = step => {
    const { categories } = this.state;
    return categories.findIndex(item => item.step === step);
  };

  getItemActive = (step, id) => {
    const { itemsSelected } = this.getCategory(step);
    return itemsSelected.find(item => item.id === id);
  };

  itemIsActive = (step, id) => {
    const findItem = this.getCategory(step).itemsSelected.find(
      item => item.id === id
    );
    return findItem;
  };

  itemIsActiveIndex = (step, id) => {
    const findItem = this.getCategory(step).itemsSelected.findIndex(
      item => item.id === id
    );
    return findItem;
  };

  getAmountSelected = category => {
    const itemsSelected = category.itemsSelected;
    const setTotalItemsSelected = (acc, val) => acc + parseInt(val.amount);
    return itemsSelected.reduce(setTotalItemsSelected, 0) + 1;
  };

  addAmountToItem = (step, id, add = true) => {
    const { categories } = this.state;
    const category = this.getCategory(step);
    const categoryIndex = this.getCategoryIndex(step);
    const item = this.itemIsActive(step, id);

    const totalItemSelected = this.getAmountSelected(category);

    if (!add && item.amount >= 1) {
      if (item.amount === 1) return false;
      item.amount = item.amount - 1;
    }

    if (add && totalItemSelected <= category.upgradeableLimit) {
      item.amount = item.amount + 1;
      const itemIndex = this.itemIsActiveIndex(step, id);

      category.itemsSelected[itemIndex] = item;
      categories[categoryIndex] = category;
    }

    this.setState({ categories });
    this.getTotalPriceInCategory();
  };

  getTotalSelectedBaseElements = category => {
    return category.itemsSelected.filter(item => item.isBase);
  };

  getTotalSelectedAdditionalsElements = category => {
    return category.itemsSelected.filter(item => !item.isBase);
  };

  setItemActive = (step, id) => {
    const { categories } = this.state;
    let updateCategory;
    const findItem = this.itemIsActive(step, id);
    const findCategory = this.getCategory(step);
    const findCategoryIndex = this.getCategoryIndex(step);
    const totalIngredients = findCategory.itemsSelected.length;
    const getTotalAmountSelected = this.getAmountSelected(findCategory);

    const {
      hasExtensible,
      showExtensiblePromotion,
      isExtensible,
      hasPortionBase,
      hasPortionAdditional,
      portionBaseAmount,
      portionAdditionalAmount,
      upgradeableLimit
    } = findCategory;

    const totalPortions = portionBaseAmount + portionAdditionalAmount;

    if (findItem) {
      let itemsSelected = findCategory.itemsSelected.filter(
        item => item.id !== id
      );

      const withoutItems = itemsSelected.length === 0;

      if (withoutItems) {
        findCategory.showExtensiblePromotion = false;
      }

      if (isExtensible && withoutItems) {
        findCategory.isExtensible = false;
      }

      updateCategory = {
        ...findCategory,
        itemsSelected
      };

      const getBaseAmount = this.getTotalSelectedBaseElements(updateCategory);
      const getAdditionalsAmount = this.getTotalSelectedAdditionalsElements(
        updateCategory
      );

      if (
        getBaseAmount.length < portionBaseAmount &&
        getAdditionalsAmount.length > 0
      ) {
        getAdditionalsAmount[0].isBase = true;
        updateCategory = {
          ...updateCategory,
          getAdditionalsAmount
        };
      }
    } else {
      if (
        upgradeableLimit > 0 &&
        getTotalAmountSelected - 1 >= upgradeableLimit
      )
        return false;

      if (hasExtensible && !isExtensible && !showExtensiblePromotion) {
        findCategory.showExtensiblePromotion = true;
      }

      const isUnlimited =
        findCategory.hasUnlimitedAdditional || findCategory.hasUnlimitedBase;

      if (totalIngredients + 1 > totalPortions && !isUnlimited) return false;

      if (totalPortions === 1 && !isUnlimited) {
        const itemSelected = {
          id,
          amount: 1,
          isBase: hasPortionBase
        };
        updateCategory = {
          ...findCategory,
          itemsSelected: [itemSelected]
        };
      } else {
        const getBaseAmount = this.getTotalSelectedBaseElements(findCategory)
          .length;
        const itemIsBase =
          getBaseAmount + 1 <= portionBaseAmount ||
          findCategory.hasUnlimitedBase;

        let itemSelected = {
          id,
          amount: 1,
          isBase: itemIsBase
        };

        updateCategory = {
          ...findCategory,
          itemSelecteds: findCategory.itemsSelected.push(itemSelected)
        };
      }

      if (totalPortions === 1 && !hasPortionAdditional && !hasExtensible) this.setNextStep(true);
    }

    categories[findCategoryIndex] = updateCategory;
    this.setState({
      categories
    });

    this.getTotalPriceInCategory();
  };

  validateNextButton = () => {
    const { step, productSeleted, sizeSelected } = this.state;
    const category = this.getCategory(step);
    if (step === 1) {
      return productSeleted != null;
    } else if (step === 2) {
      return sizeSelected != null;
    } else if (step >= 3) {
      if (!category) return false;
      const itemsSelected = this.getCategory(step).itemsSelected.length;
      return itemsSelected > 0;
    }
    return false;
  };

  pushToCategories = category => {
    const { categories, step } = this.state;
    category.step = step;
    const stepExist = this.getCategory(step);
    if (!stepExist) {
      categories.push({
        ...category,
        ...{
          itemsSelected: [],
          isExtensible: false,
          showExtensiblePromotion: false
        }
      });
      this.setState({
        categories
      });
    }
  };

  setRecommendation = async () => {
    await this.setState({
      isRecommendation: true,
      sizeSelected: 3
    });
    this.setNextStep();
  };

  requestStepCategory = async () => {
    let { step, productSeleted, sizeSelected, isRecommendation } = this.state;
    step = step - 2;
    const responseCategory = await fetchCategory({
      productSeleted,
      sizeSelected,
      step,
      isRecommendation
    });
    this.pushToCategories(responseCategory);
  };

  getExtensibleItemsSelected = step => {
    const category = this.getCategory(step);
    return (
      category.extensibleItems &&
      category.extensibleItems.filter(item => item.selected)
    );
  };

  selectExtensibleItem = (step, id) => {
    const { categories } = this.state;
    const category = this.getCategory(step);
    const categoryIndex = this.getCategory(step);
    const extensibleItems = category.extensibleItems;
    const totalSelectedExtensibleItems = extensibleItems.filter(
      item => item.selected
    );
    const isSelectedExtensibleItem = extensibleItems.find(
      item => item.selected && item.id === id
    );
    const item = extensibleItems.find(item => item.id === id);
    const itemIndex = extensibleItems.findIndex(item => item.id === id);

    if (totalSelectedExtensibleItems.length + 1 <= +category.extensibleLimit) {
      item.selected = !item.selected;
      category.extensibleItems[itemIndex] = item;
      categories[categoryIndex] = category;
      this.setState({
        categories
      });
    } else if (
      isSelectedExtensibleItem &&
      totalSelectedExtensibleItems.length + 1 >= +category.extensibleLimit
    ) {
      item.selected = !item.selected;
      category.extensibleItems[itemIndex] = item;
      categories[categoryIndex] = category;
      this.setState({
        categories
      });
    }
  };

  async componentDidMount() {
    const responseCountries = await fetchCountries();
    this.setState({
      countries: responseCountries
    });
  }

  handleCountry = async e => {
    const country = e.target.value;
    const responseCities = await fetchCities(country);
    this.setState({
      categoryId: country,
      cities: responseCities,
      countryId: country,
      headquarters: []
    }
    );
  };

  handleCity = async e => {
    const { countryId } = this.state;
    const city = e.target.value;
    const headquarters = await fetchHeadquarters(countryId, city);
    this.setState({
      cityId: city,
      headquarters
    });
  };

  handleSubmitHeadquarter = async () => {
    const headquartersId = document.getElementById('headQuarterPicker').value;
    const responseProducts = await fetchProducts(headquartersId);
    if (!responseProducts.length) {
      return toast.error("No encontramos productos en esta franquicia, lo sentimos.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
    this.setState({
      headquartersId,
      products: responseProducts
    });
  }

  render() {
    const getToken = localStorage.getItem("token");
    if (!getToken) {
      return <Redirect to="/" />;
    }
    const {
      categories,
      products,
      sizes,
      step,
      productSeleted,
      sizeSelected,
      isDelivery,
      showOptionsBeforeToContinueToSizes,
      isRecommendation,
      total,
      shoppingSet,
      actualSubtotal,
      headquarters,
      headquartersId,
      countries,
      cities,
      countryId,
      cityId
    } = this.state;

    const product = this.getProduct(productSeleted);
    const category = this.getCategory(step);
    const size = this.getSize(sizeSelected);

    return (
      <div>
        <div className="App">
          <Navigation
            setBackStep={this.setBackStep}
            step={step}
            validation={this.validateNextButton}
            setNextStep={this.setNextStep}
            category={category}
            sizeSelected={sizeSelected}
            productSeleted={productSeleted}
            getProduct={this.getProduct}
            getSize={this.getSize}
            categories={categories}
            getItemActive={this.getItemActive}
            itemIsActive={this.itemIsActive}
            actualSubtotal={actualSubtotal}
            getExtensibleItemsSelected={this.getExtensibleItemsSelected}
            total={this.state.total}
            setFinishedPicker={this.setFinishedPicker}
            showOptionsBeforeToContinueToSizes={
              showOptionsBeforeToContinueToSizes
            }
            shoppingSet={shoppingSet}
            summarySizeSelectedAndCategories={
              this.summarySizeSelectedAndCategories
            }
            removeOrderInShoppingSet={this.removeOrderInShoppingSet}
          />

          <div className="containerSteps">
            {!headquartersId ? (
              <div className="HeadquarterContainer">
                <div className="BoxShadowWithoutHover">
                  <h4>Ubica un Gokela cercano</h4>
                  <hr />

                  <div>
                    <h4>Pais</h4>
                    <select
                      id="countryPicker"
                      disabled={!countries.length}
                      onChange={this.handleCountry}
                      defaultValue={countryId ? countryId : null}
                    >
                      <option selected disabled>
                        Selecciona un Pais
                      </option>
                      {countries &&
                        countries.map((country, key) => {
                          return (
                            <option value={country} key={key}>
                              {country}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div>
                    <h4>Ciudad</h4>
                    <select
                      id="cityPicker"
                      disabled= {!cities.length}
                      onChange={this.handleCity}
                      defaultValue={cityId ? cityId : null  }
                    >
                      <option selected disabled>
                        Selecciona una Ciudad
                      </option>
                      {cities &&
                        cities.map((city, key) => (
                          <option value={city} key={key}>
                            {city}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <h4>Sede preferida</h4>
                    <select
                      id="headQuarterPicker"
                      disabled={!headquarters.length}
                    >
                      <option selected disabled>
                        Selecciona una sede
                      </option>
                      {headquarters &&
                        headquarters.map((headquarter, key) => (
                          <option value={headquarter.id} key={key}>
                            {headquarter.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="GreenInput">
                    <button onClick={this.handleSubmitHeadquarter} >Seleccionar esta Sede</button>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}

            {!showOptionsBeforeToContinueToSizes &&
            step === 1 &&
            headquartersId ? (
              <ProductsContainer
                products={products}
                getProductSelected={this.getProductSelected}
                setProduct={this.setProduct}
              />
            ) : (
              <div />
            )}

            {showOptionsBeforeToContinueToSizes && step === 1 && (
              <ChoiseOptionModal
                product={product}
                setNextStep={this.setNextStep}
                setRecommendation={this.setRecommendation}
              />
            )}

            {!isRecommendation && step === 2 ? (
              <div className="ParentContainerSize">
                <Row className="ContainerSizes AppMargin">
                  <Sizes
                    items={sizes}
                    getProductSelected={this.getProductSelected}
                    getSizeSelected={this.getSizeSelected}
                    setSize={this.setSize}
                    setSizesCollection={this.setSizeCollection}
                    setNextStep={this.setNextStep}
                  />
                </Row>
              </div>
            ) : (
              <div />
            )}

            {isRecommendation || step >= 3 ? (
              <div className="AppMargin">
                <Category
                  categories={categories}
                  itemIsActive={this.itemIsActive}
                  setItemActive={this.setItemActive}
                  getItemActive={this.getItemActive}
                  globalStep={step}
                  addAmountToItem={this.addAmountToItem}
                  setExtensible={this.setExtensible}
                  selectExtensibleItem={this.selectExtensibleItem}
                  setNextStep={this.setNextStep}
                  setSkipButton={this.setSkipButton}
                />
              </div>
            ) : (
              <div />
            )}

            {step === "*" && (
              <Resume
                sizeSelected={sizeSelected}
                productSeleted={productSeleted}
                getProduct={this.getProduct}
                getSize={this.getSize}
                categories={categories}
                getExtensibleItemsSelected={this.getExtensibleItemsSelected}
                getItemActive={this.getItemActive}
                itemIsActive={this.itemIsActive}
                getSizeSelected={this.getSizeSelected}
                getCategory={this.getCategory}
                size={size}
                total={total}
                actualSubtotal={actualSubtotal}
                setDelivery={this.setDelivery}
                isDelivery={isDelivery}
                setDeliveryPersonalInformation={
                  this.setDeliveryPersonalInformation
                }
                insertInOrderShoppingSet={this.insertInOrderShoppingSet}
                shoppingSet={shoppingSet}
                summarySizeSelectedAndCategories={
                  this.summarySizeSelectedAndCategories
                }
                removeOrderInShoppingSet={this.removeOrderInShoppingSet}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Wizard;
