import { apiDomain } from '../consts';

const fetchCreateOrder = async (data) => {
    return await fetch(`${apiDomain}/orders/create`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(data)
    }).then(response => response.json());
};

export {
    fetchCreateOrder
};