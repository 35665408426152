import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { apiDomain } from "../../consts";

class Products extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { props } = this;
    const { items, setProduct, getProductSelected } = props;
    const products = items.map((item, key) => {
      const { color, name, image, id } = item;
      const productIsSelected = getProductSelected() === id;
      return (
        <Col key={key} md="4" onClick={() => setProduct(id)}>
          <div className="ActivationProduct">
            {productIsSelected && <div className="SelectedProduct">✓</div>}
            <div className="ImageProduct">
              <img
                src={`${apiDomain}${image}`}
                height="150"
                width="150"
                alt="Product"
              />
            </div>
            <div className="ContainerProduct" style={{ borderColor: color }}>
              <div
                className="HeaderProduct BreakingBreadFont"
                style={{ backgroundColor: color }}
              >
                {name}
              </div>
            </div>
          </div>
        </Col>
      );
    });
    return products;
  }
}

export default Products;
