import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { fetchRequestSignIn } from "../services/user";
import { toast } from "react-toastify";
import { Redirect, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 5000,
  draggable: false
});

class App extends Component {
  state = {
    username: null,
    password: null,
    isLoading: false
  };

  handleUsername = e => {
    const username = e.target.value;
    this.setState({
      username
    });
  };

  handlePassword = e => {
    const password = e.target.value;
    this.setState({
      password
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      isLoading: true
    });
    const { username, password } = this.state;
    const response = await fetchRequestSignIn(username, password);
    if (response.token) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("data", JSON.stringify(response.user));
      this.notifySuccess("Has iniciado sesión correctamente!");
    } else {
      this.notifyError(response.message);
    }
    this.setState({
      isLoading: false
    });
  };

  notifySuccess = message => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER
    })
  }

  notifyError = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  };


  render() {
    const { isLoading } = this.state;
    const token = localStorage.getItem("token");
    if (token) {
      return <Redirect to="/" />;
    }

    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <div className="AppMain">
          <div className="CategoryMainContainer">
            <div className="LogoGreen">
              <img src="./logo-green.png" alt="This is the green logo of the Gokelas" />
            </div>
            <p className="TextWelcome">Iniciar sesión en Gokela</p>
            <div className="GreenInput BoxShadow">
              <input
                autoFocus
                type="text"
                placeholder="Correo electronico"
                onChange={e => this.handleUsername(e)}
                disabled={isLoading}
              />
            </div>
            <div className="GreenInput BoxShadow">
              <input
                type="password"
                placeholder="Contraseña"
                onChange={e => this.handlePassword(e)}
                disabled={isLoading}
              />
            </div>
            <div className="OrangeInput">
              <button type="submit" className="BoxShadow" disabled={isLoading}>
                Iniciar sesión
              </button>
            </div>
            <hr />
            <div className="afterRegister TextWelcome">
              <Link className="TextWelcome BreakingBreadFont" to="/registrar">
                Registrarme
              </Link>{" "}
              <br />
              <Link className="TextWelcome BreakingBreadFont" to="/recuperar">
                Recuperar mi cuenta
              </Link>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default App;
