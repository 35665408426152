import React, { Component } from "react";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Redirect } from "react-router-dom";

// const Links = () => {
//   return (
//     <div className="CategoryIngredientsContainer row">
//       <div className="CenterElements IngredientsContainer col-sm-2">
//         <FontAwesomeIcon icon={faSignInAlt} className="IconElement" />
//         <p className="LabelElement">Conectarme</p>
//       </div>
//     </div>
//   );
// };

// const ButtonAllowed = (props) => {
//   const { onClick } = props;
//   return (
//     <div className="CategoryIngredientsContainer row">
//       <Link to="/wizard" className="MenuSignInUser">
//         <Button variant="warning" className="ButtonArmaTuMenu">
//           <FontAwesomeIcon icon={faUtensils} className="IconElement" />
//           Arma tu Menu Ahora!
//         </Button>
//       </Link>

//       <div className="MenuSignInUser">
//         <Button
//           variant="danger"
//           className="ButtonArmaTuMenu"
//           onClick={() => onClick()}
//         >
//           <FontAwesomeIcon icon={faSignOutAlt} className="IconElement" />
//           Salir de mi cuenta
//         </Button>
//       </div>
//     </div>
//   );
// };

class App extends Component {
  state = {
    isLogout: false
  };

  SignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    this.setState({isLogout: true})
  };

  getUserData = () => {
    return JSON.parse(localStorage.getItem("data"));
  };

  render() {
    const { isLogout } = this.state;
    const getToken = !isLogout && localStorage.getItem("token");
    if (!getToken) {
      return <Redirect to="/entrar" />;
    }

      return (
        <div className="AppMain ">
          <div className="CategoryMainContainer blurred-box ">
            <div className="LogoGreen">
              <img src="./logo-green.png" alt="This is the green logo of the Gokela" />
            </div>
            <Link to="/wizard">
              <div className="MenuToPage BoxShadow">
                <div className="CoverMenu">
                  <FontAwesomeIcon
                    icon={faShoppingBasket}
                    className="IconElementMenu"
                  />
                  <p className="TextWelcome TextCoverMenu BreakingBreadFont">
                    ordenar online
                  </p>
                </div>
              </div>
            </Link>

            <div className="OrangeInput">
              <button
                type="submit"
                className="BoxShadow"
                onClick={() => this.SignOut()}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      );
  }
}

export default App;
