import React, { Component } from "react";
import {
  fetchPutForgotPasswordCode,
  fetchPutRepeatPassword
} from "../services/user";
import { toast } from "react-toastify";
import { Redirect, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 5000,
  draggable: false
});

const RedirectIfWeDontHaveEmail = () => {
  const url = new URLSearchParams(window.location.search);
  if (!url.get("email")) {
    return <Redirect to="/" />;
  }
  return "";
};

class PutRecoverAccountCode extends Component {
  state = {
    code: null,
    password: null,
    repeatPassword: null,
    changePassword: false,
    isLoading: false,
    isFinishedPasswordChanged: false
  };

  handleRepeatPassword = e => {
    const repeatPassword = e.target.value;
    this.setState({
      repeatPassword
    });
  };

  handleCode = e => {
    const code = e.target.value;
    this.setState({
      code
    });
  };

  handlePassword = e => {
    const password = e.target.value;
    this.setState({
      password
    });
  };

  useQuery = () => {
    return new URLSearchParams(window.location.search);
  };

  handleSubmit = async e => {
    e.preventDefault();
    const input_code = e.target.querySelector("#input_code").value;
    this.setState({
      isLoading: true,
      code: input_code
    });

    const email = this.useQuery().get("email");
    const response = await fetchPutForgotPasswordCode(email, input_code);
    if (response.changePassword) {
      this.setState({
        changePassword: true
      });
    } else {
      this.sendErrors(response);
    }
    this.setState({
      isLoading: false
    });
  };

  showErrorMessage = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  };

  showSucessMessage = message => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER
    })
  }

  sendErrors = response => {
    const { errors, message } = response;
    if (errors && errors.length) {
      errors.forEach(error => this.showErrorMessage(error));
    } else {
      this.showErrorMessage(message);
    }
  };

  handleSubmitNewPassword = async e => {
    e.preventDefault();
    const input_password = e.target.querySelector("#password").value;
    const input_repeat_password = e.target.querySelector("#repeat_password")
      .value;
    const email = this.useQuery().get("email");

    this.setState({
      isLoading: true,
      password: input_password,
      repeat_password: input_repeat_password
    });

    if (input_password !== input_repeat_password) {
      this.showErrorMessage("Contraseñas deben ser iguales");
    } else if (input_password.length <= 6) {
      this.showErrorMessage(
        "Las contraseñas deben ser mayor o igual a 6 caracteres"
      );
    } else {
      const response = await fetchPutRepeatPassword(
        email,
        input_password,
        input_repeat_password
      );
      if (response.passwordUpdated) {
        this.showSucessMessage('Contraseña actualizada correctamente');
        this.setState({
          isFinishedPasswordChanged: true
        });
      } else {
        this.showErrorMessage(response);
      }
    }
    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading, changePassword, isFinishedPasswordChanged } = this.state;

    if (isFinishedPasswordChanged) {
      return <Redirect to="/entrar" />;
    }

    const FormToValidateCode = () => {
      return (
        <form onSubmit={e => this.handleSubmit(e)}>
          <p className="TextWelcome">Digita tu codigo de recuperación</p>
          <div className="GreenInput BoxShadow">
            <input
              autoFocus
              type="text"
              placeholder="Escribe tu codigo aqui"
              disabled={isLoading}
              id="input_code"
            />
          </div>
          <div className="OrangeInput">
            <button type="submit" className="BoxShadow" disabled={isLoading}>
              Validar este codigo
            </button>
          </div>
        </form>
      );
    };

    const FormToChangePassword = () => {
      return (
        <form onSubmit={e => this.handleSubmitNewPassword(e)}>
          <p className="TextWelcome">Escribe tu Contraseña</p>
          <div className="GreenInput BoxShadow">
            <input
              autoFocus
              autoComplete="new-password"
              type="password"
              placeholder="Nueva Clave"
              disabled={isLoading}
              id="password"
              tabIndex={1}
            />
          </div>
          <div className="GreenInput BoxShadow">
            <input
              type="password"
              autoComplete="new-password"
              placeholder="Repetir Nueva Clave"
              disabled={isLoading}
              id="repeat_password"
              tabIndex={2}
            />
          </div>
          <div className="OrangeInput">
            <button type="submit" className="BoxShadow" disabled={isLoading}>
              Cambiar tu Clave
            </button>
          </div>
        </form>
      );
    };

    return (
      <div className="AppMain">
        <div className="CategoryMainContainer">
          <div className="LogoGreen">
            <img src="./logo-green.png" alt="This is the green logo of the gokela" />
          </div>

          <RedirectIfWeDontHaveEmail />

          {changePassword ? <FormToChangePassword /> : <FormToValidateCode />}
          <hr />
          <div className="afterRegister TextWelcome">
            <Link className="TextWelcome BreakingBreadFont" to="/registrar">
              Registrarme
            </Link>
            <br />
            <Link className="TextWelcome BreakingBreadFont" to="/entrar">
              Iniciar sesión
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PutRecoverAccountCode;
