import React, { Component } from "react";
import { Prices } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
const ParticularInvoice = props => {
  const {
    categories,
    productSeleted,
    sizeSelected,
    getProduct,
    getSize,
    getExtensibleItemsSelected,
    getItemActive,
    itemIsActive,
    getValue
  } = props;
  const product = getProduct(productSeleted);
  const size = getSize(sizeSelected);
  return (
    <div>
      <h1>{product.name} </h1>
      <SizeSelector size={size} />
      {categories.map((category, key) => {
        const extensibleItemsSelected = getExtensibleItemsSelected(
          category.step
        );
        if (category.itemsSelected.length === 0) return false;
        return (
          <div className="ItemsContainer" key={key}>
            <p className="CategoryLabel">{category.title}</p>

            <GetAllIngredients
              category={category}
              getItemActive={getItemActive}
              itemIsActive={itemIsActive}
              getValue={getValue}
            />
            <ExtensibleContainer
              category={category}
              extensibleItemsSelected={extensibleItemsSelected}
            />
          </div>
        );
      })}
    </div>
  );
};

const TotalSummatoryCategories = props => {
  const { isDelivery, deliveryCharge, pickupDiscount, total, actualSubtotal } = props;
  return (
    <div className="TotalPriceContainer">
      {isDelivery !== null ? (
        <div className="CategoryLabelSmall">
          <span className="CategoryLabel" style={{ width: "70%" }}>
            {isDelivery
              ? "DOMICILIO"
              : `RECOGERLO (${pickupDiscount}% Descuento)`}
          </span>

          {isDelivery ? (
            <Prices price={deliveryCharge} className="PriceOnItem" />
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div />
      )}

<div className="CategoryLabelSmall">
        <span className="CategoryLabel" style={{ width: "70%" }}>
          SUBTOTAL (PRODUCTO ACTUAL)
        </span>
        <Prices price={actualSubtotal} className="PriceOnItem" />
      </div>

      <div className="CategoryLabelSmall">
        <span className="CategoryLabel" style={{ width: "70%" }}>
          TOTAL A PAGAR (ORDEN)
        </span>
        <Prices price={total} className="PriceOnItem" />
      </div>
    </div>
  );
};

const ExtensibleContainer = props => {
  const { category, extensibleItemsSelected } = props;
  const isValidExtensible = category.isExtensible && category.hasExtensible;
  if (!isValidExtensible) return <div />;
  return (
    <div className="ItemsContainer">
      <div className="CategoryLabelSmall">
        <span className="ItemOnCategory CategoryLabelSmall">
          * * {category.extensibleTitle}
        </span>
        <Prices price={category.priceExtensible} className="PriceOnItem" />
      </div>
      <div className="ExtensibleItems">
        {extensibleItemsSelected &&
          extensibleItemsSelected.map((itemSelected, key) => {
            return (
              <span key={key} className="marginToExten">
                {key + 1}. {itemSelected.name} <br />
              </span>
            );
          })}
      </div>
    </div>
  );
};

const GetAllIngredients = props => {
  const { category, getItemActive, itemIsActive, getValue } = props;
  return category.ingredients.map((item, key) => {
    const thisItemIsActive = getItemActive(category.step, item.id);
    const getSelectedItem = itemIsActive(category.step, item.id);

    const totalPrice = getValue({
      getSelectedItem,
      thisItemIsActive,
      category,
      item
    });

    return (
      <div key={key}>
        <IngredientDescription
          getSelectedItem={getSelectedItem}
          item={item}
          totalPrice={totalPrice}
          thisItemIsActive={thisItemIsActive}
        />
      </div>
    );
  });
};

const IngredientDescription = props => {
  const { key, getSelectedItem, item, totalPrice, thisItemIsActive } = props;
  if (!thisItemIsActive) return <div />;
  return (
    <div className="CategoryLabelSmall" key={key}>
      <span className="ItemOnCategory">
        {getSelectedItem.isBase ? "(Base) " : "(Adic) "}
        {item.name}
        {getSelectedItem.amount > 1 && ` x${getSelectedItem.amount}`}
      </span>
      {totalPrice > 0 && <Prices price={totalPrice} className="PriceOnItem" />}
    </div>
  );
};

const SizeSelector = props => {
  const { size } = props;
  if (!size) return <div />;
  return (
    <div>
      <p className="CategoryLabel">Tamaño:</p>
      <div className="ItemsContainer">
        <div className="CategoryLabelSmall">
          <span className="ItemOnCategory CategoryLabelSmall">{size.name}</span>
          <Prices price={size.price} className="PriceOnItem" />
        </div>
      </div>
    </div>
  );
};

class TotalContainer extends Component {
  constructor(props) {
    super();
  }

  async componentDidMount() {}

  getValue = ({ getSelectedItem, thisItemIsActive, category, item }) => {
    let responsePrice = 0;
    if (!getSelectedItem && !thisItemIsActive) return false;

    const amount = thisItemIsActive.amount;
    const isBase = thisItemIsActive.isBase;
    const isChargeBase = category.isChargeBase;
    const isChargeAdditional = category.isChargeAdditional;
    const isBaseAndChargeable = isBase && amount > 1 && isChargeBase;
    const isAdditionalAndChargeable =
      !isBase && amount >= 1 && isChargeAdditional;

    if (isBase && amount === 1) responsePrice = isChargeBase ? item.price : 0;

    if (isBaseAndChargeable || isAdditionalAndChargeable)
      responsePrice = item.price * (isBase ? amount - 1 : amount);

    return responsePrice;
  };

  render() {
    const {
      sizeSelected,
      productSeleted,
      getProduct,
      getSize,
      categories,
      getExtensibleItemsSelected,
      getItemActive,
      itemIsActive,
      className,
      total,
      isDelivery,
      shoppingSet,
      removeOrderInShoppingSet,
      isFinished,
      actualSubtotal
    } = this.props;
    const product = getProduct(productSeleted);
    const totalOrders = shoppingSet.length;
    const { deliveryCharge, pickupDiscountPercentage } = product;

    return (
      <div className={className}>
        <div className="invoice">
          <ParticularInvoice
            categories={categories}
            sizeSelected={sizeSelected}
            productSeleted={productSeleted}
            getProduct={getProduct}
            getSize={getSize}
            getExtensibleItemsSelected={getExtensibleItemsSelected}
            getItemActive={getItemActive}
            itemIsActive={itemIsActive}
            getValue={this.getValue}
          />

          {isFinished ? (
            <div>
              <p className="CategoryLabel">Otros Productos ({totalOrders})</p>
              {!shoppingSet.length ? (
                <p>
                  Aún no tienes órdenes guardadas, termina la actual y agrega
                  más.
                </p>
              ) : (
                <div />
              )}
              {shoppingSet.map((order, key) => {
                const { productId, subtotal } = order;
                const product = getProduct(productId);
                return (
                  <div className="OrderContainer" key={key}>
                    <p className="OrderContainerProductName">{product.name}</p>
                    <Prices price={subtotal} className="PriceOnOrder" />
                    <button
                      className="ButtonTrash BoxShadow"
                      onClick={() => removeOrderInShoppingSet(key)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="ButtonTrash_Icon"
                      />{" "}
                      Borrar
                    </button>
                  </div>
                );
              })}
              <hr />
              <TotalSummatoryCategories
                actualSubtotal={actualSubtotal}
                total={total}
                isDelivery={isDelivery}
                deliveryCharge={deliveryCharge}
                pickupDiscount={pickupDiscountPercentage}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default TotalContainer;
