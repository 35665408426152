import React, { Component } from "react";
import {  Form } from "react-bootstrap";
import { fetchForgotPassword } from "../services/user";
import { toast } from "react-toastify";
import { Redirect, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 5000,
  draggable: false
});

class RecoverAccount extends Component {
  state = {
    email: null,
    isReset: false,
    isLoading: false
  };

  handleEmail = e => {
    const email = e.target.value;
    this.setState({
      email
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      isLoading: true
    });
    const { email } = this.state;
    const response = await fetchForgotPassword(email);
    if (response.approved) {
      toast.success(
        "Se ha enviado un correo con un código de recuperación, revisa por favor",
        {
          position: toast.POSITION.BOTTOM_CENTER
        }
      );
      localStorage.setItem("resetToken", response.resetToken);
      this.setState({
        isReset: true
      });
    } else {
      if (response.errors) {
        let errorMessages = "";
        response.errors.forEach(e => {
          errorMessages += e + "\n";
        });
        this.notifyError(errorMessages);
      } else {
        this.notifyError(response.message);
      }
    }
    this.setState({
      isLoading: false
    });
  };

  notifyError = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  };


  render() {
    const { isLoading, email, isReset } = this.state;
    if (isReset) return <Redirect to={"/codigo-recuperacion?email=" + email} />;

    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <div className="AppMain">
          <div className="CategoryMainContainer">
            <div className="LogoGreen">
              <img
                src="./logo-green.png"
                alt="This is the green logo of the Gokelas"
              />
            </div>
            <p className="TextWelcome">Recuperemos tu cuenta</p>
            <div className="GreenInput BoxShadow">
              <input
                autoFocus
                type="text"
                placeholder="Correo electronico"
                onChange={e => this.handleEmail(e)}
                disabled={isLoading}
              />
            </div>
            <div className="OrangeInput">
              <button type="submit" className="BoxShadow" disabled={isLoading}>
                Recuperar cuenta
              </button>
            </div>
            <hr />
            <div className="afterRegister TextWelcome">
              <Link className="TextWelcome BreakingBreadFont" to="/registrar">
                Registrarme
              </Link>
              <br />
              <Link className="TextWelcome BreakingBreadFont" to="/entrar">
                Iniciar sesión
              </Link>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default RecoverAccount;
