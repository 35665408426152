import React, { Component } from "react";
import TotalContainer from "../TotalContainer/TotalContainer";
import { Col, Row } from "react-bootstrap";
import { faTruck, faHands, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormResume = props => {
  const {
    handleSubmit,
    handleFullName,
    handleAddress,
    handlePhone,
    handleObservations,
    insertInOrderShoppingSet
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="FormDelivery">
        <h1>Información Personal</h1>
      </div>
      <div className="BasicInput">
        <input
          type="text"
          placeholder="Nombre completo"
          onChange={handleFullName}
        />
      </div>
      <div className="BasicInput">
        <input
          type="text"
          placeholder="Dirección Completa"
          onChange={handleAddress}
        />
      </div>
      <div className="BasicInput">
        <input type="phone" placeholder="Telefono" onChange={handlePhone} />
      </div>
      <div className="BasicInput">
        <input
          type="phone"
          placeholder="Observaciones"
          onChange={handleObservations}
        />
      </div>

      <div className="GreenInput">
        <button type="submit" className="BoxShadow">
          Enviar solicitud y Pagar
        </button>
      </div>

      <div className="OrangeInput">
        <button
          type="button"
          className="BoxShadow"
          onClick={() => insertInOrderShoppingSet()}
        >
          Realizar otra compra
        </button>
      </div>
    </form>
  );
};

class Resume extends Component {
  state = {
    fullName: null,
    address: null,
    phone: null,
    observations: null
  };

  handleSubmit = e => {
    e.preventDefault();
    const { fullName, address, phone, observations } = this.state;
    // const { setDeliveryPersonalInformation, total } = this.props;
    const { total } = this.props;
    const handler = window.ePayco.checkout.configure({
      key: "491d6a0b6e992cf924edd8d3d088aff1",
      test: false
    });
    const data = {
      name: "Alimento Gokela Personalizado",
      description: "Producto, Categoria y Ingredientes totalmente Personalizado.",
      invoice: "1234",
      currency: "cop",
      amount: total,
      tax_base: "0",
      tax: "0",
      country: "co",
      lang: "es",
      external: "false",
      confirmation: "http://gokela.com/pagos/1234/success",
      response: "http://gokela.com/pagos/1234/reject",
      name_billing: fullName,
      address_billing: address,
      mobilephone_billing: phone,
      extra1: observations
    };
    return handler.open(data);
    // return setDeliveryPersonalInformation({
    //   fullName,
    //   address,
    //   phone,
    //   observations
    // });
  };

  handleFullName = e => {
    const value = e.target.value;
    this.setState({
      fullName: value
    });
  };

  handleAddress = e => {
    const value = e.target.value;
    this.setState({
      address: value
    });
  };

  handlePhone = e => {
    const value = e.target.value;
    this.setState({
      phone: value
    });
  };

  handleObservations = e => {
    const value = e.target.value;
    this.setState({
      observations: value
    });
  };

  render() {
    const {
      productSeleted,
      sizeSelected,
      getProduct,
      getSize,
      product,
      categories,
      getExtensibleItemsSelected,
      getItemActive,
      itemIsActive,
      isDelivery,
      setDelivery,
      total,
      insertInOrderShoppingSet,
      shoppingSet,
      summarySizeSelectedAndCategories,
      removeOrderInShoppingSet,
      actualSubtotal
    } = this.props;

    const classNameDefault = "DeliveryWayButton BoxShadow ";

    const classNameDelivery =
      classNameDefault +
      (isDelivery === true ? "selected borderSelected" : "unselected");

    const classNamePickUp =
      classNameDefault +
      (isDelivery === false ? "selected borderSelected" : "unselected");

    return (
      <div className="ResumeContainer">
        <div className="ResumeTotal BoxShadow">
          <FontAwesomeIcon icon={faReceipt} className="IconReceipt" />
          <h1>Resumen</h1>
          <Row>
            <Col>
              <div className="DeliveryWay">
                <h1>Metodo de Entrega</h1>

                <div className="DeliveryWayButtons">
                  <div
                    className={classNameDelivery}
                    onClick={() => setDelivery(true)}
                  >
                    <FontAwesomeIcon icon={faTruck} />
                    Domicilio
                  </div>
                  <div
                    className={classNamePickUp}
                    onClick={() => setDelivery(false)}
                  >
                    <FontAwesomeIcon icon={faHands} />
                    Recogerlo
                  </div>
                </div>
              </div>
              <FormResume
                handleSubmit={this.handleSubmit}
                handleFullName={this.handleFullName}
                handleAddress={this.handleAddress}
                handlePhone={this.handlePhone}
                handleObservations={this.handleObservations}
                insertInOrderShoppingSet={insertInOrderShoppingSet}
              />
            </Col>
            <Col>
              <TotalContainer
                isFinished={true}
                sizeSelected={sizeSelected}
                productSeleted={productSeleted}
                getSize={getSize}
                getProduct={getProduct}
                product={product}
                isDelivery={isDelivery}
                categories={categories}
                getExtensibleItemsSelected={getExtensibleItemsSelected}
                getItemActive={getItemActive}
                itemIsActive={itemIsActive}
                total={total}
                actualSubtotal={actualSubtotal}
                className="TotalResumeContainer"
                shoppingSet={shoppingSet}
                summarySizeSelectedAndCategories={
                  summarySizeSelectedAndCategories
                }
                removeOrderInShoppingSet={removeOrderInShoppingSet}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Resume;
