import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import './index.css';
import Wizard from './pages/Wizard';
import App from "./pages/App";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RecoveryAccount from "./pages/RecoverAccount";
import PutRecoveryAccount from "./pages/PutRecoveryAccountCode";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from './serviceWorker';

const RouterContainer = () => {
    return (
      <Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={App} />
            <Route
              exact
              path="/codigo-recuperacion"
              component={PutRecoveryAccount}
            />
            <Route exact path="/recuperar" component={RecoveryAccount} />
            <Route exact path="/wizard" component={Wizard} />
            <Route exact path="/entrar" component={Login} />
            <Route exact path="/registrar" component={Register} />
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </Fragment>
    );
}

ReactDOM.render(<RouterContainer />, document.getElementById("root"));
serviceWorker.unregister();
