import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faReceipt,
  faTimes,
  faShoppingBag
} from "@fortawesome/free-solid-svg-icons";
import Prices from "../Prices";
import TotalContainer from "../TotalContainer/TotalContainer";

const TotalInfoLabel = props => {
  const {
    toggleMenu,
    total,
    productSeleted,
    getProduct,
    step,
    shoppingSet
  } = props;
  const isResumenStep = step === "*";
  const totalProductsSelected = shoppingSet.length + 1;
  const product = getProduct(productSeleted);
  if (!product || isResumenStep) return <div />;
  return total > 0 ? (
    <div className="PriceOnToolbar BoxShadow" onClick={() => toggleMenu()}>
      <div className="CircleCountOrders">{totalProductsSelected}</div>
      <div className="ShoppingBagContainer">
        <FontAwesomeIcon icon={faShoppingBag} />
      </div>
      <div className="ContainerPrice">
        <span className="PriceDescription">Total</span>
        <Prices price={total} className="ColorBlack" />
      </div>
    </div>
  ) : (
    <div />
  );
};

const BackStep = props => {
  const { setBackStep, step, showOptionsBeforeToContinueToSizes } = props;
  if (step <= 1 && !showOptionsBeforeToContinueToSizes) return <div />;
  return (
    <div
      className="MainHeaderButton ButtonIsLeft ButtonIsBack"
      onClick={() => setBackStep()}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
};

const StepContainer = props => {
  const { categoryName, step } = props;
  const resumenStepClassName =
    step === "*" ? "StepDescriptionResume" : "StepDescription";
  return (
    <div className="StepContainer">
      <div className="StepSelectorContainer">
        {step !== "*" ? (
          <span className="SeleccioneWord">Seleccionar</span>
        ) : (
          <div />
        )}
        <span className={resumenStepClassName}>{categoryName}</span>
      </div>
    </div>
  );
};

const LogoContainer = () => {
  return (
    <div className="Logo">
      <img src="./logo.png" width={130} height={50} alt="Gokela Logotipo" />
    </div>
  );
};

const NextButton = props => {
  const { setNextStep, category, validation, step } = props;
  const isLatestCategory = category && category.isLatest;
  const getNextButton = isLatestCategory ? faReceipt : faAngleRight;
  const isDisabledClassName = validation()
    ? "ButtonIsEnable request-loader"
    : "ButtonIsDisable";

  if (step === "*") return <div />;
  return (
    <div
      className={"MainHeaderButton ButtonIsRight " + isDisabledClassName}
      onClick={() => setNextStep()}
    >
      <FontAwesomeIcon icon={getNextButton} />
    </div>
  );
};

class Navigation extends Component {
  state = {
    categoryMenu: false
  };

  priceCalculation = (price, isBase, amount) => {
    const totalAmount = isBase ? amount - 1 : amount;
    return price * totalAmount;
  };

  toggleMenu = () => {
    const { categoryMenu } = this.state;
    this.setState({
      categoryMenu: !categoryMenu
    });
  };

  render() {
    let categoryName;
    const { categoryMenu } = this.state;

    const {
      validation,
      setNextStep,
      setBackStep,
      step,
      category,
      productSeleted,
      sizeSelected,
      getProduct,
      getSize,
      categories,
      getItemActive,
      itemIsActive,
      getExtensibleItemsSelected,
      total,
      showOptionsBeforeToContinueToSizes,
      shoppingSet,
      summarySizeSelectedAndCategories,
      removeOrderInShoppingSet,
      actualSubtotal,
    } = this.props;

    if (step === 1) {
      categoryName = "Base";
    } else if (step === 2) {
      categoryName = "Tamaño";
    } else if (step === "*") {
      categoryName = "Resumen de compra";
    } else if (category) {
      categoryName = category.title;
    }

    return (
      <div className="MainHeader">
        <BackStep
          step={step}
          setBackStep={setBackStep}
          showOptionsBeforeToContinueToSizes={
            showOptionsBeforeToContinueToSizes
          }
        />
        <StepContainer categoryName={categoryName} step={step} />
        <LogoContainer />
        <TotalInfoLabel
          step={step}
          total={total}
          toggleMenu={this.toggleMenu}
          productSeleted={productSeleted}
          getProduct={getProduct}
          shoppingSet={shoppingSet}
        />

        <NextButton
          setNextStep={setNextStep}
          category={category}
          validation={validation}
          step={step}
        />

        {categoryMenu && (
          <div className="menuResumeContainer">
            <div className="closeMenu" onClick={this.toggleMenu}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <TotalContainer
              shoppingSet={shoppingSet}
              isFinished={false}
              sizeSelected={sizeSelected}
              productSeleted={productSeleted}
              getSize={getSize}
              getProduct={getProduct}
              categories={categories}
              getExtensibleItemsSelected={getExtensibleItemsSelected}
              getItemActive={getItemActive}
              itemIsActive={itemIsActive}
              className="TotalPageContainer"
              total={total}
              actualSubtotal={actualSubtotal}
              summarySizeSelectedAndCategories={summarySizeSelectedAndCategories}
              removeOrderInShoppingSet={removeOrderInShoppingSet}
            />
          </div>
        )}
        <div className="navbarBottom"></div>
      </div>
    );
  }
}

export default Navigation;
